import './Contact.css';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useInView, animated } from '@react-spring/web';


import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

export const Contact = () => {
    const [ref, springs] = useInView(
        () => ({
          from: {
            opacity: 0,
            y: -100,
          },
          to: {
            opacity: 1,
            y: 0,
          },
        }),
        {
          rootMargin: '-10% 0%',
        }
    )
    return(
        <footer className="contact-footer">
            <animated.div className="footer-content" ref={ref} style={springs}>
            <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(7, 1fr)' ,
                justifyContent: 'center',
                alignItems: 'center', 
                height: '85%'
                }}>
                <div className="social-wrapper"></div>
                <a href="mailto:nicholas.hioe@gmail.com" target="_blank" rel="noopener noreferrer" aria-label="Contact me via email" className="social-wrapper">
                    <EmailIcon alt="email icon" fontSize="inherit" className="social-contact-icon email"/>
                </a>
                <div className="social-wrapper">
                    <div className="footer-divider"></div>
                </div>
                <a href="https://github.com/nhioe" target="_blank" rel="noopener noreferrer" aria-label="Find me on GitHub" className="social-wrapper">
                        <GitHubIcon alt="github logo" fontSize="inherit" className="social-contact-icon github"/>
                </a>
                <div className="social-wrapper">
                    <div className="footer-divider"></div>
                </div>
                <a href="https://www.linkedin.com/in/nicholas-hioe" target="_blank" rel="noopener noreferrer" aria-label="Connect with me on LinkedIn" className="social-wrapper">
                    <LinkedInIcon alt="linkedin logo" fontSize="inherit" className="social-contact-icon linkedin"/>
                </a>
                <div className="social-wrapper"></div>
            </Box>
            <Box sx={{ 
                display: 'flex', 
                paddingBottom: 4,
                paddingLeft: 4,
                height: '15%'
                }}>
                <Typography variant="p" textTransform="uppercase" letterSpacing="0.25vw" color="white">
                    ©2024 N. Hioe
                </Typography>
            </Box>
            </animated.div>
        </footer>
    )
};

export default Contact;
