import uwlogo from '../../assets/logos/uwlogo.png';
import orionlogo from '../../assets/logos/orion-health-logo.webp';
import mathsoclogo from '../../assets/logos/mathsoc-logo.png';
import miovisionlogo from '../../assets/logos/miovision-logo.png';

import uwaterloo from '../../assets/experience/universityofwaterloo.jpeg';
import uwaterloo2 from '../../assets/experience/uwaterloo2.jpeg';
import uwaterloo3 from '../../assets/experience/uwaterloo3.jpg';
import waterloostreets from '../../assets/experience/waterloostreets.jpg';
import traffic from '../../assets/experience/traffic.jpg';
import traffic2 from '../../assets/experience/traffic2.jpg';
import toronto from '../../assets/experience/toronto.jpg';
import toronto2 from '../../assets/experience/toronto2.jpg';

import apache from  '../../assets/technologies/Apache.svg';
import typescript from  '../../assets/technologies/TypeScript.svg';
import cypress from '../../assets/technologies/Cypress.svg';
import playwright from '../../assets/technologies/Playwright.svg';
import postman from '../../assets/technologies/Postman.svg';
import css from '../../assets/technologies/CSS3.svg';
import expressjs from '../../assets/technologies/Express.svg';
import html from '../../assets/technologies/HTML5.svg';
import javascript from '../../assets/technologies/JavaScript.svg';
import python from '../../assets/technologies/Python.svg';
import bash from '../../assets/technologies/Bash.svg';
import sass from '../../assets/technologies/Sass.svg';

export const experienceData = [
    {
        type: 'job',
        images: [toronto, toronto2],
        title: 'Orion Health',
        date: '05/2024 - 08/2024',
        icon: orionlogo,
        job: 'Implementation Consultant',
        location: 'Toronto, Canada',
        technologies: [
            {src: html, name: 'html'},
            {src: css, name: 'css'},
            {src: javascript, name: 'javascript'},
            {src: typescript, name: 'typescript'},
            {src: playwright, name: 'playwright'},
            {src: python, name: 'python'},
            {src: bash, name: 'bash'},
        ],
        text: [
            'Ontario Health Clinical/Patient Viewer Team',
            'Configured apps within clinical portals in JavaScript and CSS.',
            'Established test automation architecture for clinical portals.',
            'Automated nightly test runs and integrated email report generation.',
        ]
    },
    {
        type: 'job',
        images: [uwaterloo2, uwaterloo3],
        title: 'UW MathSoc',
        date: '05/2024 - 08/2024',
        icon: mathsoclogo,
        job: 'Web Developer',
        location: 'Waterloo, Canada',
        technologies: [
            {src: html, name: 'html'},
            {src: sass, name: 'sass'},
            {src: typescript, name: 'typescript'},
            {src: expressjs, name: 'express.js'},
        ],
        text: [
            'Website Migration Team',
            'Migrated existing site from WordPress to Express-based CMS.',
            'Developed database JSON schemas to support custom CMS.',
            'Templated a responsive front-end in Pug.js.',
            'Built React components for the CMS interface.',
        ]
    },
    {
        type: 'term',
        images: [waterloostreets, uwaterloo],
        title: '2B Term',
        date: '01/2024 - 04/2024',
        icon: uwlogo,
        location: 'Waterloo, Canada',
        text: [
            'Attended UofTHacks 11.',
            'CS 251 - Computer Organization and Design',
            'CS 241 - Foundations of Sequential Programs',
            'CS 240 - Data Structures and Data Management',
            'BET 350 - Customer Experience Design',
            'BET 210 - Business Technology and Infrastructure',
            'HEALTH 105 - Mental Health Literacy',
        ]
    },
    {
        type: 'term',
        images: [uwaterloo3, uwaterloo2],
        title: '2A Term',
        date: '09/2023 - 12/2023',
        icon: uwlogo,
        location: 'Waterloo, Canada',
        text: [
            'CS 246 - Object-Oriented Software Development',
            'CS 245 - Logic and Computation',
            'MATH 239 - Introduction to Combinatorics',
            'STAT 230 - Probability',
            'ECON 101 - Introduction to Microeconomics',
        ]
    },
    {
        type: 'job',
        images: [traffic2, traffic],
        title: 'Miovision',
        date: '05/2023 - 08/2023',
        icon: miovisionlogo,
        job: 'Software Quality Analyst',
        location: 'Kitchener, Canada',
        technologies: [
            {src: javascript, name: 'javascript'},
            {src: cypress, name: 'cypress'},
            {src: apache, name: 'apache jmeter'},
            {src: postman, name: 'postman'},
        ],
        text: [
            'Traffic Data Online (TDO) Team',
            'Developed e2e tests for traffic data platform.',
            'Automated load testing in JMeter and Postman.', 
            'Led pre-release testing for a traffic data configuration tool.',    
        ]
    },

    {
        type: 'term',
        images: [uwaterloo, waterloostreets],
        title: '1B Term',
        date: '01/2023 - 04/2023',
        icon: uwlogo,
        location: 'Waterloo, Canada',
        text: [
            'Attended UofTHacks X.',
            'CS 136 - Elementary Algorithm Design and Data Abstraction',
            'CS 136L - Tools and Techniques for Software Development',
            'MATH 138 - Calculus 2 for Honours Mathematics',
            'MATH 136 - Linear Algebra 1 for Honours Mathematics',
            'ENGL 109 - Introduction to Academic Writing',
            'CLAS 104 - Classical Mythology',
        ]
    },
    {
        type: 'term',
        images: [waterloostreets, uwaterloo3],
        title: '1A Term',
        date: '09/2022 - 12/2022',
        icon: uwlogo,
        location: 'Waterloo, Canada',
        text: [
            'CS 135 - Designing Functional Programs',
            'MATH 137 - Calculus 1 for Honours Mathematics',
            'MATH 135 - Algebra for Honours Mathematics',
            'ENGL 108D - Digital Lives',
            'CLAS 100 - An Introduction to Classical Studies',
        ]
    },
]

export default experienceData;