import './Projects.css';
import React from 'react';
import { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTrail, animated } from '@react-spring/web';
import GitHubIcon from '@mui/icons-material/GitHub';

import projectData from './projectData.js';

export const GalleryTrail = ( { open, breakpoints } ) => {
  const isBelowLarge = breakpoints[0];
  const isBelowSmall = breakpoints[1];
  const isBelowXSmall = breakpoints[2];
  const [horizontalDivider, setHorizontalDivider] = useState(Math.floor((projectData.length - 1) / 3));
  const [verticalDivider, setVerticalDivider] = useState(2);

  useEffect(() => {
    isBelowLarge ? setHorizontalDivider(Math.floor((projectData.length - 1) / 2)) : setHorizontalDivider(Math.floor((projectData.length - 1) / 3));
    if (isBelowSmall || isBelowXSmall) setHorizontalDivider(0);
  }, [isBelowLarge, isBelowSmall, isBelowXSmall]);

  useEffect(() => {
    isBelowLarge ? setVerticalDivider(1) : setVerticalDivider(2);
  }, [isBelowLarge]);

  const trails = useTrail(projectData.length, {
    opacity: open ? 1 : 0,
    from: { opacity: 0 }
  })

  const horizontalAnimation = useTrail(2 + horizontalDivider, {
    width: open ? '100%' : '0%',
    from: { width: '0%' },
  });

  const verticalAnimation = useTrail(verticalDivider, {
    height: open ? '100%' : '0%',
    from: { height: '0%' },
  })

  return (
    <div className="grid-container">
      {trails.map((props, index) => (
        <animated.div key={index} style={props} className="grid-item">
            <div className="grid-frame">
              <div className="image-overlay">
                <div className="overlay-content">
                    <Typography variant="h6" className="grid-text">{projectData[index].text}</Typography>
                    {
                      projectData[index].link === '' ? (
                        <Tooltip disableInteractive title="Please contact me for more information!">
                           <GitHubIcon alt={projectData[index].title + " github"} className="github-link"/>
                        </Tooltip>
                      ) : (
                      <Tooltip disableInteractive title={projectData[index].description}>
                        <a href={projectData[index].link} target="_blank" rel="noopener noreferrer">
                          <GitHubIcon alt={projectData[index].title + " github"} className="github-link"/>
                        </a>
                      </Tooltip>
                      )
                    }
                   <div className="technologies">
                      {projectData[index].technologies.map((tech, index) => (
                        <Tooltip key={index} disableInteractive title={tech.name}>
                          <img src={tech.src} alt={tech.name} />
                        </Tooltip>
                      ))}
                  </div>
                </div>
              </div>
              <img src={projectData[index].src} className="image-content" alt={projectData[index].text} />
            </div>
        </animated.div>
      ))}
      {
        isBelowXSmall || isBelowSmall ? (
        <>
        </>
        ) : (
        <>
          {
            Array.from({ length: verticalDivider }).map((_, index) => {
              return(
                <animated.div
                  key={index}
                  style={{...verticalAnimation[index], left: `calc(100% / ${verticalDivider + 1} * ${index + 1})` }}
                  className="divider-vertical"
                />                
              );
            })
          }
          {
            // (_, index), first means placeholder, not needed
            Array.from({ length: horizontalDivider + 2 }).map((_, index) => {
              return(
              <animated.div
                key={index + verticalDivider}
                style={{...horizontalAnimation[index], top: `calc((100% / ${horizontalDivider + 1} * ${index}` }}
                className="divider-horizontal"
                />
              );
            })
          }
        </> 
        )
      }
    </div>
  )
}

export const Projects = ({ inView, beforeView }) => {
  const theme = useTheme();
  const isBelowLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isBelowSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowXSmall = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    // if single col, just eliminate animation fade out
    if (isBelowSmall) {
      setOpen(true);
      return;
    }
    if (inView) setOpen(true);
    if (!inView && beforeView) setOpen(false);
  }, [isBelowSmall, inView, beforeView]);


  const [open, setOpen] = useState(false);

  return(
      <GalleryTrail open={open} breakpoints={[isBelowLarge, isBelowSmall, isBelowXSmall]}/>
  );
}

export default Projects;